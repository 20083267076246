// noinspection JSUnusedGlobalSymbols

// TODO use config/env?
const BaseUrl = "https://crafatar.com"

export interface CrafatarOptions {
  size: number
  scale: number
  overlay: boolean
  default: string | 'MHF_Alex' | 'MHF_Steve'
}

function avatarUrl(uuid?: string, options?: Partial<CrafatarOptions>): string | undefined {
  if (uuid === undefined) {
    // fall back to steve
    uuid = "c06f8906-4c8a-4911-9c29-ea1dbd1aab82"
  }
  let url = new URL(`/avatars/${uuid}`, BaseUrl)
  if (options) {
    Object.entries(options).forEach(([key, val]) => {
      if (val !== undefined) {
        url.searchParams.set(key, String(val))
      }
    })
  }
  return url.href
}

export function useCrafatar() {
  return {
    avatarUrl
  }
}
